<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("redeem_confirm_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('redeem_confirm_page.form_desc')"
    ></div>
    <!-- type 1-->
    <div v-if="couponType == '1'">
      <div class="card">
        <div class="card__info">
          <div class="scan-filter">
            <coupon-filters
              class="s-mb-3 s-mt-3"
              :options="filterOptions"
              v-model="scanFilter"
            ></coupon-filters>
          </div>

          <div>
            <!-- QRCode scanner start -->
            <div v-if="scanFilter == 'qrcode'" class="qrcode-panel">
              <QRCodeScanner v-if="showQrCodeScanner" @onScan="onScan" :qrbox="qrbox"/>
            </div>
            <!-- QRCode scanner end -->

            <!-- Code manual input start -->
            <div v-else-if="scanFilter == 'manual'">
              <input v-model="barcode" class="search__input m-auto w-100" />
            </div>
            <!-- Code manual input end -->

            <!-- content start-->
            <div>
              <Loading v-if="showLoading" loading-message="載入中..." />
              <div v-else-if="!showLoading">
                <div
                  v-html="getModuleConfig('redeem_confirm_page.content')"
                  class="p-4 rounded redeem--content"
                ></div>
              </div>
            </div>
            <!-- content end-->
          </div>
        </div>
      </div>
    </div>
    <!-- type 2-->
    <div v-if="couponType == '2'">
      <div class="card">
        <div class="row" v-if="step == '1'">
          <div class="card__title ml-2 mt-2 mb-2">選擇商家</div>
          <div class="col-12 px-2" v-for="(option, idx) in options" :key="idx">
            <SharedRadio
              v-model="proxy"
              :id="`proxy-${idx}`"
              :value="option.value"
              name="proxy"
            >
              {{ option.text }}
            </SharedRadio>
          </div>
        </div>
        <div v-if="step == '2'">
          <div class="scan-filter">
            <coupon-filters
              class="s-mb-3 s-mt-3"
              :options="filterOptions"
              v-model="scanFilter"
            ></coupon-filters>
          </div>

          <div>
            <!-- QRCode scanner start -->
            <div v-if="scanFilter == 'qrcode'" class="qrcode-panel">
              <QRCodeScanner v-if="showQrCodeScanner" @onScan="onScan" :qrbox="qrbox"/>
            </div>
            <!-- QRCode scanner end -->

            <!-- Code manual input start -->
            <div v-else-if="scanFilter == 'manual'">
              <input v-model="barcode" class="search__input m-auto w-100" />
            </div>
            <!-- Code manual input end -->

            <!-- content start-->
            <div>
              <Loading v-if="showLoading" loading-message="載入中..." />
              <div v-else-if="!showLoading">
                <div
                  v-html="getModuleConfig('redeem_confirm_page.content')"
                  class="p-4 rounded redeem--content"
                ></div>
              </div>
            </div>
            <!-- content end-->
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('redeem_confirm_page.button_text') ||
        getModuleConfig('redeem_confirm_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton
        v-if="step == '1'"
        class="s-btn-bg-primary mb-2"
        @click="changeStep"
        :disabled="!proxy"
        >確認商家</SharedButton
      >

      <SharedButton
        v-if="step == '2'"
        class="s-btn-bg-primary mb-2"
        @click="redeemCoupon"
        :disabled="!canSubmit"
        >{{ getModuleConfig("redeem_confirm_page.button_text") }}</SharedButton
      >

      <MemberButton
        :button-style="`s-btn-outline-primary`"
        :button-text="getModuleConfig('redeem_confirm_page.cancel_button_text')"
        :button-url="getModuleConfig('redeem_confirm_page.cancel_button_url')"
        :type="getModuleConfig('redeem_confirm_page.cancel_button_action')"
      ></MemberButton>

      <!-- <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("redeem_confirm_page.cancel_button_text")
      }}</SharedButton> -->
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import couponMixin from "@/mixins/liff/coupon";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import QRCodeScanner from "@/components/QRCodeScanner";
import SharedRadio from "@/components/DynamicForm/Radio.vue";
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue";
import { mapActions } from "vuex";
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";

export default {
  mixins: [couponMixin, abbottAndMixin],
  components: {
    Loading,
    SharedButton,
    QRCodeScanner,
    SharedRadio,
    MemberButton,
    CouponFilters,
  },
  data() {
    return {
      qrbox: {
        width: 220,
        height: 220,
      },
      showLoading: false,
      couponCode: "",
      barcode: null,
      options: [],
      proxy: null,
      step: "1",
      couponType: null,
      showBarcodeInput: false,
      filterOptions: [
        {
          key: "qrcode",
          text: "QR Code 掃描",
        },
        {
          key: "manual",
          text: "手動輸入",
        },
      ],
      showQrCodeScanner: true,
      scanFilter: "qrcode",
    };
  },

  computed: {
    canSubmit() {
      if (this.couponType == "1" && this.barcode) {
        return true;
      }
      if (this.couponType == "2" && this.proxy) {
        const provider = this.options.find((item) => item.value === this.proxy);
        if (provider.verify && this.barcode) {
          return true;
        }
        if (!provider.verify) {
          return true;
        }
      }
      return false;
    },
  },
  async mounted() {
    this.step = '1'
    await this.fetchCoupon();
  },
  watch: {
    proxy: {
      handler: function (val) {
        this.onRadioChange(this.options.find((item) => item.value === val));
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions("projAbbottAnd", [
      "setVerifyCode",
      "setStoreCode",
      "setCouponIdData",
      "setCouponType",
    ]),
    onRadioChange(provider) {
      if (!provider) {
        return;
      }
      if (provider.verify) {
        this.showBarcodeInput = true;
      }
      this.setStoreCode(this.proxy);

    },
    changeStep() {
      if (this.canSubmit) {
        this.redeemCoupon()
      } else {
        this.step = '2'
      }
    },
    async redeemCoupon() {
      await this.setVerifyCode(this.barcode);
      await this.setCouponIdData(this.$route.params.id);
      await this.setCouponType(this.couponType);
      this.nextConfirmAction();
    },
    async nextConfirmAction() {
      await this.confirmCoupon();
    },
    async onScan(decodedText) {
      this.barcode = decodedText;
      this.scanFilter = "manual";
    },
    async fetchCoupon() {
      await abbottAndApi
        .getCouponDetail(this.$route.params.orgCode, {
          coupon_id: this.$route.params.id,
        })
        .then(async (res) => {
          this.couponCode = res.data.data.code;
          if (this.couponCode.includes("BG_H")) {
            this.couponType = "2";
          } else {
            this.couponType = "1";
            this.showBarcodeInput = true;
          }
          if (this.couponType == "2") {
            await this.fetchStoreList();
          }
        });
    },
    async fetchStoreList() {
      await abbottAndApi
        .getEventStoreList(this.$route.params.orgCode)
        .then(async (res) => {
          this.options = res.data.data.map((item) => {
            return {
              text: item.storeName,
              value: item.storeCode,
              verify: item.needVerify,
            };
          });
        });
    },
  },
};
</script>

<style lang="scss">
.redeem {
  &--content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.search {
  &__input {
    background: #ffffff;
    border-radius: 10px !important;
    border: 1px solid var(--liff-button-color);
    padding: 15px 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }

    &:focus {
      border: 1px solid var(--liff-button-color);
      outline: none;
    }
  }
}

.qrcode-panel {
    width: 70%;
    margin: auto;
}

.page {
  padding-bottom: 150px !important;
}

</style>
